import React, { useState } from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";
import Faq from "react-faq-component";
import scrollToElement from "scroll-to-element";

const data = {
    rows: [
        {
            title: "Z czego wynika cena wdrożenia GA4?",
            content:
                "Cena wdrożenia Google Analytics 4 (GA4) może różnić się w zależności stopnia złożoności naszych prac w obszarze: Integracji - Połączenie GA4 z innymi narzędziami lub systemami. Konfiguracji zaawansowanych zdarzeń. Stworzenia szczegółowych raportów lub analiz. Twojego oczekiwanego poziomu wdrożenia w zakresie dalszego korzystania z GA4. Utrzymania i aktualizacji GA4 po wdrożeniu.",
        },
        {
            title: "Ile czasu zajmuje wdrożenie GA4?",
            content:
                "Czas migracji do Google Analytics 4 w dużej mierze zależy od stopnia zaawansowania projektu. Zwykle wynosi od 7 do 14 dni roboczych. Czas migracji określamy na podstawie aktualnego stanu analityki w ramach Google Analytics Universal oraz Google Tag Manager, podamy go po audycie obecnej analityki.",
        },
        {
            title: "Czy wdrożenie GA4 jest niezbędne?",
            content:
                "Google rezygnuje z usługi Google Analytics Universal (3). Masz czas tylko do 1 lipca 2023 roku, aby dokonać przejścia na Google Analytics 4. Po tym czasie tracimy dostęp do usługi Universal Analytics oraz dostęp do zebranych tam danych.",
        },
        {
            title: "Czy jestem potrzebna/y przy wdrożeniu?",
            content:
                "Wdrożenie usługi GA4 jest po naszej stronie. Twój udział w procesie będzie polegał na przyznaniu nam niezbędnych dostępów, omówienia potrzeb firmy odnośnie nowej konfiguracji oraz (opcjonalnie) odbycia z nami krótkiego warsztatu wdrożeniowego, na którym pokażemy Tobie lub Twojemu zespołowi, jak wykorzystać możliwości nowego narzędzia.",
        },
        {
            title: "Który pakiet wdrożenia GA4 jest dla mnie odpowiedni?",
            content:
                "Pomożemy Ci w wyborze odpowiedniego pakietu i dopasujemy go do Twoich potrzeb. Szczególną uwagę zwracamy na: Zastosowaną technologię Twojej strony www (np. Wordpress, Shoper). Ilość niezbędnych zdarzeń Ilość niestandardowych raportów Specyfikę Twojej strony (np. e-commerce) Twoje cele biznesowe",
        },
    ],
};

const data_tabs = {
    rows: [
        {
            title: "Consent Mode Serverside",
            content: `<p class="config-ga-tabs__text">
	<strong>Consent mode</strong> - czyli tryb uzyskiwania
	zgody. Jest to nowa wersja pop-upu z informacją o
	przetwarzaniu ciasteczek, która zbiera informację o tym,
	czy użytkownik wyraża zgodę na śledzenie jego aktywności
	na stronie www. Dopiero po wyrażeniu zgody przez
	użytkownika powinny uruchamiać się skrypty narzędzi
	analitycznych.
  </p>`,
        },
        {
            title: "Server Side GTM",
            content: `<p class="config-ga-tabs__text">
	<strong>Server Side Google Tag Manager</strong> - to nowy sposób
	wykorzystania managera tagów Google (GTM) do mierzenia
	zachowań użytkowników. Standardowy GTM bazuje na
	kontenerze tagów umieszczonym w kodzie strony. Za pomocą
	kontenera implementujemy kody śledzące – np. Google
	Analitycs czy Pixel Facebook, które gromadzą i wysyłają
	informacje o użytkownikach bezpośrednio do tych
	zewnętrznych platform. W wyniku tego to one stają się
	właścicielem przesłanych danych.
  </p>
  <p class="config-ga-tabs__text">
	W odróżnieniu od standardowego GTM, kontener dla Server
	Side GTM nie jest uruchamiany w przeglądarce użytkownika,
	ale na serwerze, który jest naszą własnością.
	Pośredniczymy zatem w przesyłaniu danych użytkowników do
	Facebooka, Google itp. Mamy pełną kontrolę nad
	informacjami o użytkownikach, które przekazujemy dalej.
	Stajemy się zatem właścicielem danych.
  </p>
  <div class="config-ga-tabs__features">
	<div class="config-ga-tabs__row">
	  <div class="config-ga-tabs__col">
		<p class="config-ga-tabs__features-title">
		  Najważniejsze zalety:
		</p>
		<ul class="config-ga-tabs__list">
		  <li>
			Niższy czas wczytywania strony i wyższy wynik
			jakości strony
		  </li>
		  <li>Pełna kontrola przesyłanych danych</li>
		  <li>Bezpieczeństwo i wysoka jakość danych</li>
		  <li>
			Możliwość łączenia i wzbogacania danych (np.
			połączenie z zewnętrznym CRM)
		  </li>
		</ul>
	  </div>
	  <div class="config-ga-tabs__col">
		<p class="config-ga-tabs__features-title">
		  Wady:
		</p>
		<ul class="config-ga-tabs__list">
		  <li>
			Inwestycja – Trzeba opłacić serwer, na którym będą
			gromadzone dane. Obecnie 160 zł to cena za 1
			serwer (instancję), dla dużych serwisów www i
			e-commerce zalecane są minimum 3 instancje
		  </li>
		  <li>
			Do wdrożenia i zarządzania rozwiązaniem potrzebna
			jest wiedza techniczna
		  </li>
		</ul>
	  </div>
	</div>
  </div>`,
        },
        {
            title: "Integracja z BigQuery",
            content: `<p class="config-ga-tabs__text">
	<strong>BigQuery</strong> - jest to jedna z wielu usług
	dostępnych w Google Cloud Platform (GCP). Pozwala na
	przechowywanie oraz procesowanie dużej ilości danych.
	Odbywa się to w chmurze, na bezpiecznej, stabilnej i
	skalowalnej infrastrukturze Google Cloud Platform.
  </p>
  <p class="config-ga-tabs__text">
	BigQuery to doskonałe miejsce do integracji danych z
	różnych źródeł, na przykład danych z systemów CRM i danych
	z platform reklamowych. Big Query pozwala również na łatwe
	przygotowanie danych źródłowych do ich wizualizacji w
	narzędziach do tego przeznaczonych (np. Google Looker).
  </p>
  <p class="config-ga-tabs__text">
	Google dzięki temu narzędziu odpowiada też na dynamiczną
	sytuację związaną z prawną ochroną danych osobowych. Przy
	integracji Google Analytics 4 i BigQuery możesz łatwo
	wybrać serwer, na którym będą przechowywane dane. Możesz
	kierować się regionem, jak Unia Europejska czy Stany
	Zjednoczone, lub konkretnym krajem. Masz dostęp do
	automatycznej anonimizacji adresów IP, które były
	największą kontrowersją przy wykorzystaniu Google
	Analytics w świetle RODO. Dzięki temu możesz mieć pewność,
	że spełniasz prawne warunki związane z ochroną danych.
  </p>`,
        },
    ],
};

const GoogleAnalytics4 = ({ location: { pathname } }) => {
    const [functionalityDesc, setFunctionalityDesc] = useState(1);

    return (
        <Layout
            seo={{
                title: "Konfiguracja i wdrożenie Google Analytics 4",
                description:
                    "Wdrożenie i konfiguracja GA4 daje szeroki wachlarz nowych możliwości w zbieraniu i analizowaniu danych. Skonfigurujemy dla Ciebie w pełni Google Analytics 4. Przejdź na GA4.",
                href: pathname,
                lang: "pl",
            }}
        >
            <span className="anchor" id="home"></span>
            <div className="subpage-header subpage-header--single-offer">
                <h1 className="subpage-header__title">
                    Konfiguracja i wdrożenie
                    <br />
                    <span>Google Analytics 4</span>
                </h1>
            </div>

            <div className="single-offer-menu-container">
                <div className="row">
                    <div className="col-lg-3">
                        <Link to="/uslugi/#analityka">
                            <div className="back-wrapper">
                                <span className="back-wrapper__arrow"></span>
                                <p className="back-wrapper__title">Usługi</p>
                            </div>
                        </Link>
                        <p className="nav-title">Specjalizacje:</p>
                        <ul>
                            <li className="current">
                                <a href="#home">
                                    Konfiguracja i wdrożenie Google Analytics 4
                                </a>
                            </li>
                            <li>
                                <Link to="/wdrozenie-analityki/">
                                    Wdrożenie analityki
                                </Link>
                            </li>
                            <li>
                                <Link to="/testy-ab-google-optimize/">
                                    Testy A/B
                                </Link>
                            </li>
                            <li>
                                <Link to="/analiza-strony-internetowej-przed-startem-kampanii-ppc/">
                                    Analiza strony internetowej przed startem
                                    kampanii PPC
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="single-offer-sections single-offer-sections--category-4">
                <section className="single-offer-section-text-left--cat-4 single-offer-section-text-left">
                    <div className="row">
                        <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3">
                            <div className="google-expire-info mb-40">
                                <img
                                    src={require("../assets/images/ico-warning.svg")}
                                    alt=""
                                    className="header-container__arrow"
                                />
                                <div className="google-expire-info__text">
                                    <h4 className="text-col__title">
                                        Czy wiesz, że obecna wersja Google
                                        Analytics (Universal) będzie dostępna
                                        tylko do 1 lipca 2023 roku?
                                    </h4>
                                    <p className="text-col__desc ">
                                        Po tym terminie Universal Analytics
                                        przestanie zbierać dane i śledzić
                                        zachowania użytkowników. Zastanawiasz
                                        się pewnie co powinieneś zrobić?
                                        Rozwiązanie jest tylko jedno - przejść
                                        na Google Analytics 4. To ostatni
                                        dzwonek, aby wdrożyć GA4 i zapoznać się
                                        z jego nowymi funkcjami dopasowanymi do
                                        potrzeb Twojego biznesu. Pomożemy Ci w
                                        tym!
                                    </p>
                                </div>
                            </div>
                            <h3 className="text-col__title mb-40">
                                Google Analytics 4 - nowy wymiar analityki
                                Twojej strony
                            </h3>
                            <p className="text-col__desc mb-100">
                                GA4 to to kolejna generacja Google Analytics, w
                                której zmienia się przede wszystkim podejście do
                                gromadzenia danych. Najnowsza wersja Google
                                Analytics odpowiada na nowe potrzeby właścicieli
                                witryn i aplikacji mobilnych. W responsywnym
                                świecie, skoncentrowanym na użytkowniku GA4 daje
                                nam szeroki wachlarz nowych możliwości w
                                zbieraniu i analizowaniu danych w ramach
                                jednego, kompleksowego narzędzia.
                            </p>
                            <img
                                src={require("../assets/images/analityka-img.jpg")}
                                alt=""
                                className="img-fluid mb-140 center-img"
                            />
                            <h2 className="text-col__title mb-40">
                                Wdrażając z nami Google Analytics 4 zyskujesz
                                m.in.:
                            </h2>
                            <div className="features-items">
                                <div className="features-items__item">
                                    <img
                                        src={require("../assets/images/ico-grow.svg")}
                                        alt=""
                                    />
                                    <p>
                                        Poprawne i jakościowe dane, które
                                        wspomogą rozwój Twojego biznesu
                                    </p>
                                </div>
                                <div className="features-items__item">
                                    <img
                                        src={require("../assets/images/ico-tools.svg")}
                                        alt=""
                                    />
                                    <p>
                                        Narzędzie spersonalizowane pod potrzeby
                                        Twojej firmy
                                    </p>
                                </div>
                                <div className="features-items__item">
                                    <img
                                        src={require("../assets/images/ico-teacher.svg")}
                                        alt=""
                                    />
                                    <p>
                                        Indywidualne szkolenie dla Ciebie lub
                                        Twojego zespołu, jak wykorzystać
                                        możliwości nowego narzędzia
                                    </p>
                                </div>
                            </div>
                            <h3 className="text-col__title mb-40">A także:</h3>
                            <ul className="big-bullets mb-140">
                                <li>
                                    Bardziej precyzyjne śledzenie użytkowników
                                </li>
                                <li>
                                    Model atrybucji w oparciu o dokładne dane
                                </li>
                                <li>Nowy wygląd interfejsu użytkownika</li>
                                <li>
                                    Mierzenie aktywności użytkowników w oparciu
                                    o zdarzenia
                                </li>
                                <li>
                                    Automatyczne śledzenie danych i
                                    przechowywanie danych o użytkownikach
                                </li>
                                <li>Wskaźnik zaangażowania</li>
                                <li>segmentacja użytkowników</li>
                                <li>
                                    Zaawansowane, szczegółowe i w pełni
                                    personalizowane raporty
                                </li>
                                <li>
                                    Nowy sposób zliczania sesji - sesja jako
                                    grupa zdarzeń
                                </li>
                                <li>
                                    Listy remarketingowe w oparciu m.in o ID
                                    użytkowników
                                </li>
                                <li>Predefiniowanie celów</li>
                                <li>Dokładną analitykę reklam YouTube</li>
                                <li>
                                    Śledzenie użytkowników w aplikacji mobilnej
                                    i stronie internetowej w obrębie jednej
                                    usługi BigQuery - eksportowanie danych z
                                    systemów sprzedaży stacjonarnej
                                </li>
                            </ul>
                            <h2 className="text-col__title mb-40">
                                Wdrożenie Google Analytics 4 z WHEN
                            </h2>
                            <p className="text-col__desc">
                                Postawmy sprawę jasno. Migrację z Google
                                Universal na Google Analytics 4 możesz wykonać
                                bazując na instrukcjach dostępnych w
                                przygotowanym przez Google asystencie
                                konfiguracji. Nie będzie to jednak konfiguracja
                                szyta na miarę Twojego biznesu, uwzględniająca
                                Twoje potrzeby i indywidualne cele. Niezależnie
                                od tego, czy prowadzisz sklep internetowy,
                                jesteś deweloperem, czy świadczysz usługi,
                                jakościowe, wdrożenie Google Analytics 4 jest
                                niezbędne do mierzenia efektów i osiągnięcia
                                sukcesu prowadzonych działań marketingowych.
                                Jego konfiguracja to złożony proces, przez który
                                w całości Cię poprowadzimy, angażując minimum
                                Twojego czasu.
                            </p>
                        </div>
                    </div>
                </section>

                {/* <section className="single-offer-section-text-left--cat-4 single-offer-section-text-left">
        <div className="row">
          <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3"> */}

                <section className="single-offer-section-timeline single-offer-section-timeline--google single-offer-section-text-left--cat-4 single-offer-section-text-left">
                    <div className="row">
                        <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3">
                            <h2 className="text-col__title mt-100 mb-40">
                                Jak wygląda proces wdrożenia?
                            </h2>
                            <div className="features-items features-items--process">
                                <div className="features-items__item">
                                    <img
                                        src={require("../assets/images/ico-people-bolb.svg")}
                                        alt=""
                                    />
                                    <span>Audyt obecnej analityki</span>
                                    <p>
                                        Zweryfikujemy obecną konfigurację
                                        Universal Analytics oraz Google Tag
                                        Manager i poprawimy ewentualne błędy.
                                        Opracujemy plan migracji, zachowując
                                        ustawienia Twojej obecnej analityki lub
                                        ulepszając ją o obecne braki.
                                    </p>
                                    <img
                                        className="features-items__item__arrow"
                                        src={require("../assets/images/ico-progress-arrow.svg")}
                                        alt=""
                                    />
                                </div>
                                <div className="features-items__item">
                                    <img
                                        src={require("../assets/images/ico-google-tools.svg")}
                                        alt=""
                                    />
                                    <span>
                                        Konfiguracja konta Google Analytics 4
                                    </span>
                                    <p>
                                        Dokonamy konfiguracji konta GA4 na
                                        podstawie wniosków z przeprowadzonej
                                        analizy biznesowej, strony www i audytu
                                        obecnej konfiguracji. Zajmiemy się
                                        konfiguracją m.in. niestandardowych
                                        metryk, wymiarów i zdarzeń, dopasowanych
                                        do potrzeb Twojego biznesu.
                                    </p>
                                    <img
                                        className="features-items__item__arrow"
                                        src={require("../assets/images/ico-progress-arrow.svg")}
                                        alt=""
                                    />
                                </div>
                                <div className="features-items__item">
                                    <img
                                        src={require("../assets/images/ico-verification.svg")}
                                        alt=""
                                    />
                                    <span>Weryfikacja</span>
                                    <p>
                                        Korzystając z narzędzia do monitorowania
                                        zdarzeń przeprowadzimy testy i pełną
                                        weryfikację wdrożonej konfiguracji GA4.
                                    </p>
                                    <img
                                        className="features-items__item__arrow"
                                        src={require("../assets/images/ico-progress-arrow.svg")}
                                        alt=""
                                    />
                                </div>
                                <div className="features-items__item">
                                    <img
                                        src={require("../assets/images/ico-board.svg")}
                                        alt=""
                                    />
                                    <span>Warsztaty</span>
                                    <p>
                                        Po zakończonym wdrożeniu i testach,
                                        omówimy wspólnie najważniejsze aspekty
                                        migracji. Szczególną uwagę poświęcimy
                                        przedstawieniu przygotowanych przez nas
                                        raportów i eksploracji.
                                    </p>
                                </div>
                            </div>
                            {/* <div className="timeline-container">
              <span className="timeline-container__line"></span>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number">1</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__desc">
                      Zweryfikujemy obecną konfigurację Universal Analytics oraz
                      Google Tag Manager i poprawimy ewentualne błędy.Opracujemy
                      plan migracji, zachowując ustawienia Twojej obecnej
                      analityki lub ulepszając ją o obecne braki.
                    </p>
                  </div>
                </div>
              </div>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number">2</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__desc">
                      Dokonamy konfiguracji konta GA4 na podstawie wniosków z
                      przeprowadzonej analizy biznesowej, strony www i audytu
                      obecnej konfiguracji. Zajmiemy się konfiguracją m.in.
                      niestandardowych metryk, wymiarów i zdarzeń, dopasowanych
                      do potrzeb Twojego biznesu
                    </p>
                  </div>
                </div>
              </div>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number">3</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__desc">
                      Korzystając z narzędzia do monitorowania zdarzeń
                      przeprowadzimy testy i pełną weryfikację wdrożonej
                      konfiguracji GA4.
                    </p>
                  </div>
                </div>
              </div>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number">4</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__desc">
                      Po zakończonym wdrożeniu i testach, omówimy wspólnie
                      najważniejsze aspekty migracji. Szczególną uwagę
                      poświęcimy przedstawieniu przygotowanych przez nas
                      raportów i eksploracji
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
                        </div>
                    </div>
                </section>

                <section className="google-investment single-offer-section-text-left--cat-4 single-offer-section-text-left">
                    <div className="row ">
                        <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 mt-100">
                            <h2 className="text-col__title mb-40">
                                Inwestycja i czas wdrożenia GA4
                            </h2>
                            <p className="text-col__desc">
                                Kompleksowe wdrożenie Google Analytics 4 może
                                potrwać od 7 do 14 dni roboczych. Ostateczny
                                czas wdrożenia zależny jest od stopnia
                                zaawansowania naszych działań dla Twojej strony.
                                Dokładny czas na migracje określany jest na
                                podstawie przeprowadzonego przez nas audytu
                                obecnych struktur Twojej analityki.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="single-offer-section-pricing-table google-analytics-4__table">
                    <div className="row">
                        <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3">
                            <h2>Wybierz rozwiązanie dla siebie</h2>
                            <div className="table-wrapper">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <span>Nazwa usługi</span>
                                            </th>
                                            <th>
                                                <span>
                                                    Pakiet
                                                    <br />
                                                    podstawowy
                                                </span>
                                            </th>
                                            <th>
                                                <span>
                                                    Pakiet
                                                    <br />
                                                    zaawansowany
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="row-label">
                                                Audyt obecnej struktury kont
                                                Universal Analytics i Google Tag
                                                Manager
                                            </td>
                                            <td>
                                                <img
                                                    src={require("../assets/images/ico-checkmark.svg")}
                                                    alt=""
                                                />
                                            </td>
                                            <td>
                                                <img
                                                    src={require("../assets/images/ico-checkmark.svg")}
                                                    alt=""
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="row-label">
                                                Podstawowa konfiguracja konta
                                                Google Analytics 4
                                            </td>
                                            <td>
                                                <img
                                                    src={require("../assets/images/ico-checkmark.svg")}
                                                    alt=""
                                                />
                                            </td>
                                            <td>
                                                <img
                                                    src={require("../assets/images/ico-checkmark.svg")}
                                                    alt=""
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="row-label">
                                                Konfiguracja automatycznego
                                                śledzenia zdarzeń
                                            </td>
                                            <td>
                                                <img
                                                    src={require("../assets/images/ico-checkmark.svg")}
                                                    alt=""
                                                />
                                            </td>
                                            <td>
                                                <img
                                                    src={require("../assets/images/ico-checkmark.svg")}
                                                    alt=""
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="row-label">
                                                Konfiguracja niestandardowych
                                                zdarzeń na potrzeby konwersji
                                            </td>
                                            <td>
                                                <img
                                                    src={require("../assets/images/ico-checkmark.svg")}
                                                    alt=""
                                                />
                                            </td>
                                            <td>
                                                <img
                                                    src={require("../assets/images/ico-checkmark.svg")}
                                                    alt=""
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="row-label">
                                                Stworzenie podstawowych raportów
                                            </td>
                                            <td>
                                                <img
                                                    src={require("../assets/images/ico-checkmark.svg")}
                                                    alt=""
                                                />
                                            </td>
                                            <td>
                                                <img
                                                    src={require("../assets/images/ico-checkmark.svg")}
                                                    alt=""
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="row-label">
                                                Połączenie GA4 z Google Search
                                                Console
                                            </td>
                                            <td></td>
                                            <td>
                                                <img
                                                    src={require("../assets/images/ico-checkmark.svg")}
                                                    alt=""
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="row-label">
                                                Konfiguracja zdarzeń
                                                niestandardowych dla e-commerce
                                            </td>
                                            <td></td>
                                            <td>
                                                <img
                                                    src={require("../assets/images/ico-checkmark.svg")}
                                                    alt=""
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="row-label">
                                                Analiza i optymalizacja zdarzeń
                                                DataLayer
                                            </td>
                                            <td></td>
                                            <td>
                                                <img
                                                    src={require("../assets/images/ico-checkmark.svg")}
                                                    alt=""
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="row-label">
                                                Konfiguracja niestandardowych
                                                zdarzeń na potrzeby konwersji
                                            </td>
                                            <td></td>
                                            <td>
                                                <img
                                                    src={require("../assets/images/ico-checkmark.svg")}
                                                    alt=""
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="row-label row-tooltip row-tooltip--1">
                                                Stworzenie zaawansowanych
                                                raportów
                                            </td>
                                            <td></td>
                                            <td>
                                                <img
                                                    src={require("../assets/images/ico-checkmark.svg")}
                                                    alt=""
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="row-label">
                                                Indywidualne warsztaty ze
                                                specjalistą, podczas których
                                                dowiesz się, jak wykorzystać
                                                nową konfigurację w Twoim
                                                biznesie.
                                            </td>
                                            <td></td>
                                            <td>
                                                <img
                                                    src={require("../assets/images/ico-checkmark.svg")}
                                                    alt=""
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <div className="text-center">
                                                    <a
                                                        href="#kontakt"
                                                        className="btn btn-box prowadzenie-fb-wycena-1"
                                                        // onClick={VictorBtnAction}
                                                    >
                                                        <strong>
                                                            zapytaj o wycenę
                                                        </strong>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="text-center">
                                                    <a
                                                        href="#kontakt"
                                                        className="btn btn-box prowadzenie-fb-wycena-2"
                                                        // onClick={WinnerBtnAction}
                                                    >
                                                        <strong>
                                                            zapytaj o wycenę
                                                        </strong>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="what-else-google single-offer-section-text-left--cat-4 single-offer-section-text-left">
                    <div className="row">
                        <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 mt-100">
                            <h3 className="text-col__title mb-20">
                                Co jeszcze możemy dla Ciebie zrobić?
                            </h3>
                            <p className="text-col__desc">
                                Migracja do GA4 to początek nowych możliwości.
                                Sprawdź, co jeszcze możemy dla Ciebie zrobić,
                                aby wykorzystać inne zaawansowane
                                funkcjonalności GA4.
                            </p>
                            <div className="config-ga-tabs d-none d-lg-block">
                                <div className="config-ga-tabs__head">
                                    <button
                                        className={`config-ga-tabs__btn${
                                            functionalityDesc === 1
                                                ? " active"
                                                : ""
                                        }`}
                                        onClick={() => setFunctionalityDesc(1)}
                                    >
                                        Consent Mode Serverside
                                    </button>
                                    <button
                                        className={`config-ga-tabs__btn${
                                            functionalityDesc === 2
                                                ? " active"
                                                : ""
                                        }`}
                                        onClick={() => setFunctionalityDesc(2)}
                                    >
                                        Server Side GTM
                                    </button>
                                    <button
                                        className={`config-ga-tabs__btn${
                                            functionalityDesc === 3
                                                ? " active"
                                                : ""
                                        }`}
                                        onClick={() => setFunctionalityDesc(3)}
                                    >
                                        Integracja z BigQuery
                                    </button>
                                </div>
                                <div className="config-ga-tabs__body">
                                    {functionalityDesc === 1 && (
                                        <div className="config-ga-tabs__content">
                                            <p className="config-ga-tabs__text">
                                                <strong>Consent mode</strong> -
                                                czyli tryb uzyskiwania zgody.
                                                Jest to nowa wersja pop-upu z
                                                informacją o przetwarzaniu
                                                ciasteczek, która zbiera
                                                informację o tym, czy użytkownik
                                                wyraża zgodę na śledzenie jego
                                                aktywności na stronie www.
                                                Dopiero po wyrażeniu zgody przez
                                                użytkownika powinny uruchamiać
                                                się skrypty narzędzi
                                                analitycznych.
                                            </p>
                                        </div>
                                    )}
                                    {functionalityDesc === 2 && (
                                        <div className="config-ga-tabs__content">
                                            <p className="config-ga-tabs__text">
                                                <strong>
                                                    Server Side Google Tag
                                                    Manager
                                                </strong>{" "}
                                                - to nowy sposób wykorzystania
                                                managera tagów Google (GTM) do
                                                mierzenia zachowań użytkowników.
                                                Standardowy GTM bazuje na
                                                kontenerze tagów umieszczonym w
                                                kodzie strony. Za pomocą
                                                kontenera implementujemy kody
                                                śledzące – np. Google Analitycs
                                                czy Pixel Facebook, które
                                                gromadzą i wysyłają informacje o
                                                użytkownikach bezpośrednio do
                                                tych zewnętrznych platform. W
                                                wyniku tego to one stają się
                                                właścicielem przesłanych danych.
                                            </p>
                                            <p className="config-ga-tabs__text">
                                                W odróżnieniu od standardowego
                                                GTM, kontener dla Server Side
                                                GTM nie jest uruchamiany w
                                                przeglądarce użytkownika, ale na
                                                serwerze, który jest naszą
                                                własnością. Pośredniczymy zatem
                                                w przesyłaniu danych
                                                użytkowników do Facebooka,
                                                Google itp. Mamy pełną kontrolę
                                                nad informacjami o
                                                użytkownikach, które
                                                przekazujemy dalej. Stajemy się
                                                zatem właścicielem danych.
                                            </p>
                                            <div className="config-ga-tabs__features">
                                                <div className="config-ga-tabs__row">
                                                    <div className="config-ga-tabs__col">
                                                        <p className="config-ga-tabs__features-title">
                                                            Najważniejsze
                                                            zalety:
                                                        </p>
                                                        <ul className="config-ga-tabs__list">
                                                            <li>
                                                                Niższy czas
                                                                wczytywania
                                                                strony i wyższy
                                                                wynik jakości
                                                                strony
                                                            </li>
                                                            <li>
                                                                Pełna kontrola
                                                                przesyłanych
                                                                danych
                                                            </li>
                                                            <li>
                                                                Bezpieczeństwo i
                                                                wysoka jakość
                                                                danych
                                                            </li>
                                                            <li>
                                                                Możliwość
                                                                łączenia i
                                                                wzbogacania
                                                                danych (np.
                                                                połączenie z
                                                                zewnętrznym CRM)
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="config-ga-tabs__col">
                                                        <p className="config-ga-tabs__features-title">
                                                            Wady:
                                                        </p>
                                                        <ul className="config-ga-tabs__list">
                                                            <li>
                                                                Inwestycja –
                                                                Trzeba opłacić
                                                                serwer, na
                                                                którym będą
                                                                gromadzone dane.
                                                                Obecnie 160 zł
                                                                to cena za 1
                                                                serwer
                                                                (instancję), dla
                                                                dużych serwisów
                                                                www i e-commerce
                                                                zalecane są
                                                                minimum 3
                                                                instancje
                                                            </li>
                                                            <li>
                                                                Do wdrożenia i
                                                                zarządzania
                                                                rozwiązaniem
                                                                potrzebna jest
                                                                wiedza
                                                                techniczna
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {functionalityDesc === 3 && (
                                        <div className="config-ga-tabs__content">
                                            <p className="config-ga-tabs__text">
                                                <strong>BigQuery</strong> - jest
                                                to jedna z wielu usług
                                                dostępnych w Google Cloud
                                                Platform (GCP). Pozwala na
                                                przechowywanie oraz procesowanie
                                                dużej ilości danych. Odbywa się
                                                to w chmurze, na bezpiecznej,
                                                stabilnej i skalowalnej
                                                infrastrukturze Google Cloud
                                                Platform.
                                            </p>
                                            <p className="config-ga-tabs__text">
                                                BigQuery to doskonałe miejsce do
                                                integracji danych z różnych
                                                źródeł, na przykład danych z
                                                systemów CRM i danych z platform
                                                reklamowych. Big Query pozwala
                                                również na łatwe przygotowanie
                                                danych źródłowych do ich
                                                wizualizacji w narzędziach do
                                                tego przeznaczonych (np. Google
                                                Looker).
                                            </p>
                                            <p className="config-ga-tabs__text">
                                                Google dzięki temu narzędziu
                                                odpowiada też na dynamiczną
                                                sytuację związaną z prawną
                                                ochroną danych osobowych. Przy
                                                integracji Google Analytics 4 i
                                                BigQuery możesz łatwo wybrać
                                                serwer, na którym będą
                                                przechowywane dane. Możesz
                                                kierować się regionem, jak Unia
                                                Europejska czy Stany
                                                Zjednoczone, lub konkretnym
                                                krajem. Masz dostęp do
                                                automatycznej anonimizacji
                                                adresów IP, które były
                                                największą kontrowersją przy
                                                wykorzystaniu Google Analytics w
                                                świetle RODO. Dzięki temu możesz
                                                mieć pewność, że spełniasz
                                                prawne warunki związane z
                                                ochroną danych.
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="config-ga-tabs config-ga-tabs__accordion d-block d-lg-none">
                                <Faq
                                    data={data_tabs}
                                    styles={{
                                        rowContentPaddingBottom: "60px",
                                        rowContentPaddingTop: "10px",
                                    }}
                                    config={{
                                        arrowIcon: (
                                            <img
                                                src={require("../assets/images/ico-faq_arrow.svg")}
                                                alt=""
                                            />
                                        ),
                                    }}
                                />
                            </div>
                            <button
                                className="btn btn-box free-consulting mt-5"
                                onClick={() => scrollToElement("#kontakt")}
                            >
                                <strong>Skontaktuj się z nami</strong>
                            </button>
                        </div>
                    </div>
                </section>

                <section className="faq-google single-offer-section-text-left--cat-4 single-offer-section-text-left">
                    <div className="row">
                        <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 mt-100">
                            <h2 className="text-col__title mb-40">
                                FAQ wdrożenia Google Analytics 4
                            </h2>
                            <Faq
                                data={data}
                                styles={{
                                    rowContentPaddingBottom: "60px",
                                    rowContentPaddingTop: "10px",
                                }}
                                config={{
                                    arrowIcon: (
                                        <img
                                            src={require("../assets/images/ico-faq_arrow.svg")}
                                            alt=""
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </div>
                </section>

                <section id="kontakt" className="single-offer-section-contact">
                    <div className="form-wrapper">
                        <h2 className="single-offer-section-contact__title mb-40">
                            Skontaktuj się z nami! <br />
                            Wypełnij <span>formularz</span>
                        </h2>
                        <ContactFormDark locales={locales["pl"]} />
                    </div>
                    <img
                        className="img-fluid single-offer-section-contact__hand"
                        src={require("../assets/images/home-contact-hand-right.png")}
                        alt=""
                    />
                </section>
            </div>
        </Layout>
    );
};
export default GoogleAnalytics4;
